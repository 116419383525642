.about {
  &-section {
    &-1 {
      height: 780px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      &::before {
        content: '';
        background-image: url('../../assets/img/about-section-1.jpeg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        top: 120px;
        left: 0;
        right: 0;
        height: 780px;
        filter: brightness(75%);
        opacity: 90%;
      }

      &-title {
        font-family: 'Playfair Display', sans-serif;
        font-weight: 400;
        font-size: 100px;
        color: white;
        padding-left: 240px;

        &-container {
          position: absolute;
          z-index: 10;
        }
      }

      &-block {
        position: relative;
        display: block;
        width: 100%;
        height: 50px;
        background-color: rgb(55, 55, 55);
      }
    }

    &-2 {
      padding: 0 250px;
      height: 584px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 50px;

      &-img {
        width: 100%;

        &-container {
          width: 540px;
          flex-basis: 50%;
        }
      }

      &-info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 449px;
        flex-basis: 50%;

        &-title-1 {
          color: #4F9CAA;
          font-family: "Roboto", Sans-serif;
          font-size: 12px;
          letter-spacing: 4px;
        }

        &-title-2 {
          font-family: "Playfair Display", Sans-serif;
          font-weight: 400;
          font-size: 50px;
          line-height: 1.3em;
        }

        &-desc {
          font-family: "Roboto", Sans-serif;
          font-size: 14px;
          font-weight: 300;
          line-height: 2em;
          letter-spacing: 1.5px;
        }

        &-line {
          width: 535px;
          height: 1px;
          background-color: #5c5c5c;
        }

        &-bottom {
          display: flex;
          flex-direction: row;
          gap: 40px;
          width: 100%;

          &-socials {
            display: flex;
            flex-direction: row;
            gap: 20px;
            justify-content: center;
          }

          &-block {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 56px;

            &-title {
              color: #1C1C1C;
              font-family: "Roboto", Sans-serif;
              font-size: 12px;
              font-weight: bold;
              letter-spacing: 4px;
            }

            &-email {
              font-family: "Roboto", Sans-serif;
              font-size: 14px;
              font-weight: 300;
              letter-spacing: 0.5px;
              color: #636363;
              text-decoration: none;
            }
          }
        }
      }

    }

    &-3 {
      height: 145px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &::before {
        content: '';
        background-image: url('../../assets/img/about-section-3.jpeg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        left: 250px;
        right: 250px;
        height: 145px;
        filter: brightness(65%);
      }

      &-content {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 400px;
        align-items: center;
      }

      &-title {
        color: #FFFFFF;
        font-family: "Playfair Display", Sans-serif;
        font-size: 30px;
        font-weight: 200;
        letter-spacing: 2px;
      }

      &-icon {
        width: 60px;
        height: 60px;
      }

      &-info {
        display: flex;
        flex-direction: column;
        align-items: center;

        &-call {
          font-weight: 100;
          font-size: 30px;
          color: white;
          text-decoration: none;
        }
      }
    }

    &-4 {
      height: 622px;
      display: flex;
      color: #FFFFFF;
      flex-direction: column;
      justify-content: start;

      &::before {
        content: '';
        background-image: url('../../assets/img/about-section-4.jpeg');
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        left: 0;
        right: 0;
        height: 622px;
        filter: brightness(65%);
      }

      &-content {
        position: relative;
        top: 120px;
        display: flex;
        height: 252px;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
      }

      &-paragraph-1 {
        font-family: "Roboto", Sans-serif;
        font-size: 12px;
        letter-spacing: 4px;
      }

      &-paragraph-2 {
        font-family: "Roboto", Sans-serif;
        font-size: 14px;
        font-weight: 300;
        line-height: 2em;
        letter-spacing: 1.5px;
        text-align: center;
      }

      &-title {
        font-family: "Playfair Display", Sans-serif;
        font-size: 50px;
        line-height: 1.3em;
      }

      &-btn-container {
        display: flex;
        flex-direction: row;
        gap: 20px
      }

      &-button {
        width: 195px;
        height: 39px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: bold;
        line-height: 1em;
        letter-spacing: 4px;
        border: none;
        cursor: pointer;
        transition: transform 0.2s ease-in-out;
        text-decoration: none;

        &:hover {
          transform: scale(0.9);
        }

        &--green {
          background-color: #1a4c56;
          color: white;
        }

        &--white {
          color: black;
          background-color: white;
        }
      }

    }

    &-5 {
      height: 800px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      &::before {
        content: '';
        background-image: url('../../assets/img/main-background.jpeg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        left: 0;
        right: 0;
        height: 800px;
        filter: opacity(30%)
      }

      &-content {
        position: relative;
        width: 100%;
      }

      &-top {
        top: -140px;
        position: relative;
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-evenly;
      }

      &-bottom {
        position: relative;
        top: -80px;
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        justify-content: center;
        gap: 200px;

        &-container {
          display: flex;
          flex-direction: column;
          gap: 35px
        }

      }

      &-block {
        display: flex;
        flex-direction: column;
        gap: 5px
      }

      &-num {
        color: #C19B76;
        font-family: "Roboto", Sans-serif;
        font-size: 12px;
        letter-spacing: 2px;
      }

      &-line {
        height: 1px;
        width: 200px;
        background-color: #C19B76;
      }

      &-title {
        color: #1C1C1C;
        font-family: "Roboto", Sans-serif;
        font-size: 12px;
        font-weight: normal;
        line-height: 1.7em;
        letter-spacing: 4px;
      }

      &-paragraph {
        font-family: "Roboto", Sans-serif;
        font-size: 14px;
        font-weight: 300;
        line-height: 28px;
        letter-spacing: 1.5px;
      }
    }

    &-6 {
      height: 444px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      &::before {
        content: '';
        background-image: url('../../assets/img/about-section-6.jpeg');
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        left: 0;
        right: 0;
        height: 444px;
        filter: brightness(50%)
      }

      &-content {
        position: relative;
        display: flex;
        flex-direction: column;
        text-align: center;
      }

      &-title {
        margin-bottom: 20px;
        color: #FFFFFF;
        font-family: "Roboto", Sans-serif;
        font-size: 12px;
        letter-spacing: 4px;
      }

      &-meal {
        color: #FFFFFF;
        font-family: "Playfair Display", Sans-serif;
        font-size: 50px;
        line-height: 1.3em;
      }

      &-price {
        margin-bottom: 40px;
        color: #FFFFFF;
        font-family: "Playfair Display", Sans-serif;
        font-size: 50px;
        line-height: 1.3em;
      }

      &-second-content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 1000px;
        gap: 100px
      }

      &-icon-block {
        padding-right: 100px;
        height: 92px;
        display: flex;
        gap: 13px;
        flex-direction: column;
        border-right: 1px solid white;
        align-items: center;

        &:last-child {
          border: none;
          padding: 0;
        }
      }


      &-icon {
        width: 50px;
        height: 50px;

        &-title {
          color: #FFFFFF;
          font-family: "Roboto", Sans-serif;
          font-size: 12px;
          letter-spacing: 4px;
        }
      }
    }

    &-7 {
      height: 900px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      &::before {
        content: '';
        background-image: url('../../assets/img/second-background.jpeg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        left: 0;
        right: 0;
        height: 900px;
        filter: opacity(30%)
      }

      &-content {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 40px;
        align-items: center;
        width: 100%;

      }

      &-container {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: end;
        width: 100%;
      }

      &-main-title {
        color: #1C1C1C;
        font-family: "Playfair Display", Sans-serif;
        font-size: 50px;
        font-weight: 400;
        line-height: 1.3em;
      }

      &-title {
        color: #1C1C1C;
        font-family: "Playfair Display", Sans-serif;
        font-size: 25px;
        font-weight: 400;
        text-align: center;
        line-height: 1.3em;
        letter-spacing: 1px;
      }

      &-desc {
        text-align: center;
        font-family: "Roboto", Sans-serif;
        font-size: 14px;
        font-weight: 300;
        line-height: 28px;
        letter-spacing: 1.5px;
        height: 56px;
        width: 50%;
      }

      &-block {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 20px;
        width: 50%;
        align-items: center;
      }

      &-img {
        height: 315px;
        width: 315px;
        cursor: pointer;
        transition: transform 0.6s ease;

        &:hover {
          transform: translateY(-20px);
        }

        &--big {
          width: 420px;
          height: 512px;
        }
      }
    }
  }
}



@media only screen and (max-width: 1050px) {
  .about {
    &-section {
      &-7 {
        height: 900px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        &::before {
          content: '';
          background-image: url('../../assets/img/second-background.jpeg');
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          position: absolute;
          left: 0;
          right: 0;
          height: 900px;
          filter: opacity(30%)
        }

        &-content {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 40px;
          align-items: center;
          width: 100%;

        }

        &-container {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: end;
          width: 100%;
        }

        &-main-title {
          color: #1C1C1C;
          font-family: "Playfair Display", Sans-serif;
          font-size: 50px;
          font-weight: 400;
          line-height: 1.3em;
        }

        &-title {
          color: #1C1C1C;
          font-family: "Playfair Display", Sans-serif;
          font-size: 25px;
          font-weight: 400;
          text-align: center;
          line-height: 1.3em;
          letter-spacing: 1px;
        }

        &-desc {
          text-align: center;
          font-family: "Roboto", Sans-serif;
          font-size: 14px;
          font-weight: 300;
          line-height: 28px;
          letter-spacing: 1.5px;
        }

        &-block {
          display: flex;
          flex-direction: column;
          gap: 20px;
        }

        &-img {
          height: 315px;
          width: 300px;
          cursor: pointer;

          transition: transform 0.6s ease;

          &:hover {
            transform: translateY(-20px);
          }

          &--big {
            width: 420px;
            height: 512px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1023px) {
  .br-none {
    display: none;
  }

  .about {
    &-section {
      &-1 {
        height: 300px;
        align-items: start;

        &::before {
          content: '';
          top: 170px;
          height: 300px;
          filter: brightness(65%);
        }

        &-title {
          font-size: 26px;
          padding-left: 20px;
        }

        &-block {
          height: 20px;
        }
      }

      &-2 {
        padding: 0;
        height: 100%;
        flex-direction: column;
        max-width: 280px;
        margin: auto;

        &-img {
          margin-top: 50px;

          &-container {
            width: 280px;
          }
        }

        &-info {
          gap: 20px;

          &-title-1 {
            text-align: center;
          }

          &-title-2 {
            font-size: 30px;
            text-align: center;
          }

          &-desc {
            text-align: center;
            margin-bottom: 10px;
          }

          &-line {
            width: 100%;
          }

          &-bottom {
            margin-top: 10px;
            flex-direction: column;
            align-items: center;
            gap: 30px;


            &-block {
              height: 100%;
              gap: 10px;

              &-title {
                text-align: center;
              }
            }
          }
        }

      }

      &-3 {
        margin-top: 50px;
        height: 270px;
        display: flex;

        &::before {
          left: 20px;
          right: 20px;
          height: 270px;
        }

        &-content {
          flex-direction: column;
          padding: 15px 0;
          height: 100%;
        }

        &-info {

          &-call {
            text-align: center;
            width: 156px;
          }
        }
      }

      &-4 {

        &-content {
          height: 252px;
          gap: 20px;
        }


        &-paragraph-2 {
          width: 280px;
        }

        &-button {
          width: 170px;

          &-white {
            color: #1c1c1c;
          }
        }

        &-btn-container {
          flex-direction: column;
        }

      }

      &-5 {
        flex-direction: column;
        height: 100%;

        &-content {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          margin: auto;
        }

        &-top {
          top: -100px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        &-bottom {
          top: -50px;
          width: 290px;
          flex-direction: column;
          gap: 35px;
        }
      }

      &-6 {
        height: 500px;
        align-items: flex-end;

        &::before {
          left: 0;
          right: 0;
          height: 500px;
          filter: brightness(45%)
        }

        &-meal {
          font-size: 30px;
        }

        &-second-content {
          flex-direction: column;
          align-items: center;
          width: 100%;
          gap: 0
        }

        &-icon-block {
          padding-right: 0;
          border: none;

          &--second {
            border-right: 1px solid white;
            border-left: 1px solid white;
            width: 290px;
          }
        }

        &-icon {
          width: 40px;
          height: 40px;
        }
      }

      &-7 {
        height: 100%;
        flex-direction: column;
        margin-top: 50px;

        &-container {
          flex-direction: column;
          align-items: center;
          width: 100%;
          gap: 60px;
        }

        &-main-title {
          font-size: 30px;
        }

        &-desc {
          width: 240px;
        }

        &-block {
          align-items: center;
          width: 290px;
          gap: 20px;
        }

        &-img {
          height: 280px;
          width: 280px;
          cursor: pointer;

          transition: transform 0.6s ease;

          &:hover {
            transform: translateY(-20px);
          }
        }
      }
    }
  }
}