.button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 130px;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 4px;
  line-height: 12px;
  background-color:  rgb(55, 55, 55);
  color: white;
  outline: none;
  border: none;
  cursor: pointer;
  transition: transform 0.2s ease-in;
  text-transform: uppercase;
  text-decoration: none;

  &:hover {
    transform: scale(0.9);
  }
}