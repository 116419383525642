.sliderCard {
  position: relative;
  display: flex;
  width: 25%;
  height: 60%;
  border-radius: 10px;
  flex-direction: column;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  background-color: white;

  &__absolut {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 15%;
    top: 75%;
    background-color: rgba(0, 0, 0, 0.5);
    filter: blur(50%);
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    font-size: 14px;

    &__left {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }

  &__img {
    width: 100%;
    height: 85%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    object-fit: cover;
  }

  &__text-block {
    padding: 5px;
    height: 15%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    text-align: center;
  }
}

@media only screen and (max-width: 1100px) {
  .sliderCard {
    height: 80%;

    &__img {
      height: 80%;
    }

    &__absolut {
      top: 70%;
      bottom: 20%;
    }

    &__text-block {
      height: 20%;
    }
  }
}

@media only screen and (max-width: 580px) {
  .sliderCard {
    height: 80%;
    width: 40%;
  }
}
