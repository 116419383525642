.reservation {
  position: fixed;
  height: fit-content;
  width: 23vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(241, 241, 241);
  z-index: 11111111;
  display: flex;
  flex-direction: column;
  border-radius: 4px;

  &__close {
    width: 20px;
  }

  &__top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
  }

  &__title {
    font-size: 18px;
    font-weight: 300;
  }

  &__icons {
    padding: 15px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  &__icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 2px;
    gap: 5px;

    &:hover {
      background-color: rgba(201, 201, 201, 0.367);
      border-radius: 3px;
      padding: 2px;
    }

    &__selected {
      background-color: rgba(201, 201, 201, 0.367);
      border-radius: 3px;
      padding: 2px;
    }
  }

  &__icon {
    width: 23px;
    height: 20px;
    cursor: pointer;
    border-radius: 5px;

    &__text {
      font-size: 12px;
      font-weight: 300;
    }

  }
}

.line {
  height: 1px;
  width: 100%;
  background-color: rgb(198, 198, 198);
}

@media only screen and (max-width: 1100px) {
  .reservation {
    width: 25vw;

    &__title {
      font-size: 17px;
    }

    &__icon {
      width: 18px;
      height: 18px;

      &__text {
        font-size: 11px;
      }
    }
  }
}

@media only screen and (max-width: 950px) {
  .reservation {
    width: 28vw;
  }
}


@media only screen and (max-width: 820px) {
  .reservation {
    width: 30vw;
  }
}

@media only screen and (max-width: 720px) {
  .reservation {
    width: 35vw;
  }
}

@media only screen and (max-width: 620px) {
  .reservation {
    width: 45%;
  }
}

@media only screen and (max-width: 550px) {
  .reservation {
    width: 50%;
  }
}

@media only screen and (max-width: 480px) {
  .reservation {
    width: 75%;
  }
}