.confirmation {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  &__block {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    gap: 2px;
  }

  &__head {
    color: rgb(166, 166, 166);
    font-size: 12px;
    font-weight: 300;
  }

  &__paragraph {
    font-size: 15px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    gap: 10px
  }

  &__input {
    height: 30px;
    width: 100%;
    outline: none;
    border: 0.2px solid rgba(128, 128, 128, 0.367);
    padding-left: 10px;

    &::placeholder {
      color: #9d9d9d;
      font-size: 12px;
      font-weight: 300;
      font-family: 'Roboto', sans-serif;
    }
  }

  &__button {
    cursor: pointer;
    border: none;
    background-color: rgb(233, 233, 233);
    height: 25px;
    margin-top: 25px;
    color: black;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}

@media only screen and (max-width: 1100px) {
  .confirmation {
    padding: 0 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    &__head {
      color: rgb(166, 166, 166);
      font-size: 10px;
      font-weight: 300;
    }

    &__block {
      display: flex;
      flex-direction: column;
      margin-bottom: 8px;
      gap: 3px;
    }

    &__paragraph {
      font-size: 13px;
    }

    &__button {
      cursor: pointer;
      border: none;
      background-color: rgb(233, 233, 233);
      height: 25px;
      margin-top: 10px;

      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }
}
