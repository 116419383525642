.date {
  &__text {
    padding: 10px;
    font-size: 14px;
    text-align: center;
    font-weight: 300;
  }
}

.calendar-container {
  display: flex;
  align-items: center;
  justify-content: center;
}