.time {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  padding-bottom: 20px;

  &__block {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 5px
  }

  &__hour {
    font-size: 12px;
  }

  &__pm {
    font-size: 10px;
  }

  &__time-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #c0dcbb;
    border-radius: 50%;
    padding: 10px 8px;
    gap: 2px;
    cursor: pointer;
    margin-top: 5px;
    margin-right: 4px;

    &:hover {
      background-color: #9fc39a;
    }
  }
}

@media only screen and (max-width: 1100px) {
  .time {
    &__hour {
      font-size: 9px;
    }

    &__pm {
      font-size: 7px;
    }

    &__time-container {
      padding: 7px 5px;
    }
  }
}

@media only screen and (max-width: 480px) {
  .time {
    &__hour {
      font-size: 12px;
    }

    &__pm {
      font-size: 10px;
    }

    &__time-container {
      padding: 10px 8px;
    }
  }
}
