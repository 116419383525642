.header {
  position: relative;

  &__top {
    height: 44px;
    display: flex;
    align-items: center;
    width: 100%;
    color: white;


    &::before {
      content: '';
      background-image: url("../../assets/img/header-top1.jpg");
      background-position: top center;
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      left: 0;
      right: 0;
      height: 44px;
      filter: brightness(75%);
    }

    &-container {
      width: 100%;
      padding: 0 240px;
      max-height: 15px;
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      &-title {
        font-size: 13px;
        letter-spacing: 2px;
        line-height: 14px;
      }

      &-socials {
        display: flex;
        gap: 6px;
        align-items: center;
        flex-direction: row;
      }

      &-info {
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 16px;
      }
    }
  }

  &__bottom {
    height: 76px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 240px;

    &::before {
      content: '';
      background-image: url('../../assets/img/main-background.jpeg');
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      left: 0;
      right: 0;
      height: 76px;
      filter: opacity(50%)
    }

    &-menu {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &-nav {
      margin-right: 50px;
    }

    &-logo {
      height: 27px;
      padding-left: 240px;
      cursor: pointer;
    }

    &-list {
      list-style: none;
      display: flex;
      flex-direction: row;
      gap: 50px;
    }

    &-link {
      text-decoration: none;
      font-family: "Playfair Display", Sans-serif;
      font-size: 15px;
      font-weight: normal;
      line-height: 15px;
      letter-spacing: 2px;
      color: #1D1D1D;
    }
  }
}

.icon {
  height: 100%;
  transition: all 0.2s ease-in;

  &:hover {
    transform: scale(1.2);
  }

  &-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    text-decoration: none;
    height: 15px;
    color: white;

    &--dark {
      color: rgb(99, 99, 99);
    }

    &--text {
      font-size: 13px;
      letter-spacing: 2px;
      line-height: 14px;
    }
  }
}

.logo {
  position: relative;
  width: 190px;
  height: 27px;
}

/* ваші стилі для телефонів та інших пристроїв з екраном до 768px */
@media only screen and (max-width: 1023px) {
  .header {
    display: none;
  }
}