.menu-mobile {
  &-section {
    &-1 {
      &-img {
        background-image: url('../../../assets/img/about-section-2.jpeg');
        background-position: top;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 40px;
        filter: opacity(50%);
      }

      &-block {
        padding: 30px 40px;
        display: flex;
        flex-direction: column;
        gap: 30px;
        margin-bottom: 30px;
      }
    }

    &-2 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;

      &-button {
        width: 195px;
        height: 39px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: bold;
        line-height: 1em;
        letter-spacing: 4px;
        border: none;
        cursor: pointer;
        background-color: #1a4c56;
        color: white;
        transition: transform 0.2s ease-in-out;

        &:hover {
          transform: scale(0.9);
        }
      }
    }
  }
}

@media only screen and (min-width: 749px) {
  .menu-mobile {
    display: none;
  }
}