.contact {
  &-section {
    &-1 {
      height: 350px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;


      &::before {
        content: '';
        background-image: url('../../assets/img/about-section-4.jpeg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        left: 0;
        right: 0;
        height: 350px;
        filter: brightness(75%);
      }

      &-content {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
      }

      &-title {
        font-family: 'Playfair Display', sans-serif;
        color: #FFFFFF;
        font-size: 60px;
        text-transform: uppercase;
        letter-spacing: 4px;
      }
    }

    &-2 {
      margin: 50px 0;
      background-image: linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), url('../../assets/img/second-background.jpeg');
      background-repeat: no-repeat;
      background-position: -100px;
      background-size: cover;
      height: 500px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 100px;

      &-top {
        display: flex;
        flex-direction: column;
        gap: 20px
      }

      &-paragraph {
        color: #509CAB;
        font-family: "Roboto", Sans-serif;
        font-size: 12px;
        letter-spacing: 4px;
        transition: all 0.2s ease-in-out;
      }

      &-heading {
        color: #1C1C1C;
        font-family: "Playfair Display", Sans-serif;
        font-size: 50px;
        font-weight: 300;
        line-height: 1.3em;
      }

      &-desc {
        font-size: 14px;
        font-weight: 300;
        line-height: 2em;
        letter-spacing: 1.5px;
        color: #636363;
      }

      &-button {
        border: none;
        outline: none;
        width: 106px;
        height: 39px;
        background-color: rgb(80, 156, 171);
        color: white;
        font-family: "Roboto", Sans-serif;
        font-size: 12px;
        font-weight: bold;
        line-height: 1em;
        letter-spacing: 4px;
        transition: background-color 0.2s ease-in-out;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:hover {
          background-color: #C19B77;
        }
      }

      &-form {
        display: flex;
        flex-direction: column;
        gap: 25px;
        background-color: white;

        &-button {
          background-color: #C19B77;
          height: 39px;
          border: none;
          color: white;
          cursor: pointer;
        }
      }

      &-input {
        height: 38px;
        width: 600px;
        outline: none;
        border: 0.2px solid rgba(128, 128, 128, 0.367);
        padding-left: 10px;

        &::placeholder {
          color: #9d9d9d;
          font-size: 16px;
          font-weight: 300;
          font-family: 'Roboto', sans-serif;
        }
      }

      &-textarea {
        padding-top: 5px;
        height: 154px;
      }
    }

    &-3 {
      height: 550px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;


      &::before {
        content: '';
        background-image: url('../../assets/img/map.png');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        left: 0;
        right: 0;
        height: 550px;
        filter: grayscale(100%)
      }


      &-block {
        position: relative;
        background-color: #C19B77;
        width: 410px;
        height: 300px;
        padding: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      &-title {
        color: #FFFFFF;
        font-family: 'Playfair Display', sans-serif;
        font-weight: normal;
        letter-spacing: 1px;
        font-size: 23px;
      }

      &-item {
        display: flex;
        flex-direction: row;
        gap: 20px;
        align-items: center;
      }

      &-paragraph {
        color: #FFFFFF;
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 1.5px;
        transition: all 0.2s ease-in;
        &--call {
          cursor: pointer;

          &:hover {
            transform: scale(1.1);
          }
        }
      }

      &-button {
        background-color: rgb(80, 156, 171);
        outline: none;
        font-family: "Roboto", Sans-serif;
        font-size: 12px;
        font-weight: bold;
        line-height: 1em;
        letter-spacing: 4px;
        color: white;
        cursor: pointer;
        height: 40px;
        border: none;
      }
    }
  }
}

.error-message {
  background-color: #444;
  color: white;
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-weight: 400;
  height: 30px;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 1px;
  margin-bottom: -25px;
}

.icon {
  color: white;
  width: 20px;
  height: 20px;
}

@media only screen and (max-width: 1023px) {
  .br-none{ 
    display: none;
  }

  .contact {
    &-section {
      &-1 {
        height: 350px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;


        &::before {
          content: '';
          background-image: url('../../assets/img/about-section-4.jpeg');
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
          position: absolute;
          left: 0;
          right: 0;
          height: 350px;
          filter: brightness(75%);
        }

        &-content {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
        }

        &-title {
          font-family: 'Playfair Display', sans-serif;
          color: #FFFFFF;
          font-size: 60px;
          text-transform: uppercase;
          letter-spacing: 4px;
        }
      }

      &-2 {
        margin: 0;
        background-image: linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), url('../../assets/img/second-background.jpeg');
        background-repeat: no-repeat;
        background-position: 100px;
        background-size: cover;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 0;

        &-top {
          margin-top: 50px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 20px;
          padding: 0 20px;
        }

        &-paragraph {
          color: #509CAB;
          font-family: "Roboto", Sans-serif;
          font-size: 12px;
          letter-spacing: 4px;
          text-align: center;
        }

        &-heading {
          text-align: center;
          color: #1C1C1C;
          font-family: "Playfair Display", Sans-serif;
          font-size: 30px;
          line-height: 1.3em;
        }

        &-desc {
          text-align: center;
          font-size: 14px;
          font-weight: 300;
          line-height: 2em;
          letter-spacing: 1.5px;
          padding-bottom: 30px;
        }

        &-button {
          margin-bottom: 50px;
          border: none;
          outline: none;
          width: 106px;
          height: 39px;
          background-color: rgb(80, 156, 171);
          color: white;
          font-family: "Roboto", Sans-serif;
          font-size: 12px;
          font-weight: bold;
          line-height: 1em;
          letter-spacing: 4px;
          transition: background-color 0.2s ease-in-out;
          cursor: pointer;

          &:hover {
            background-color: #C19B77;
          }
        }

        &-form {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 25px;
          background-color: white;

          &-button {
            background-color: #C19B77;
            width: 80%;
            height: 39px;
            border: none;
            color: white;
            cursor: pointer;
          }
        }

        &-input {
          height: 38px;
          width: 80%;
          outline: none;
          border: 0.2px solid rgba(128, 128, 128, 0.367);
          padding-left: 10px;

          &::placeholder {
            color: #9d9d9d;
            font-size: 16px;
            font-weight: 300;
            font-family: 'Roboto', sans-serif;
          }
        }

        &-textarea {
          padding-top: 5px;
          height: 154px;
        }
      }

      &-3 {
        margin-top: 50px;
        margin-bottom: -50px;
        padding: 0 20px;
        height: 550px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;


        &::before {
          content: '';
          background-image: url('../../assets/img/map.png');
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
          position: absolute;
          left: 0;
          right: 0;
          height: 550px;
          filter: grayscale(100%)
        }


        &-block {
          position: relative;
          background-color: #C19B77;
          width: 100%;
          height: 300px;
          padding: 20px 40px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }

        &-title {
          color: #FFFFFF;
          font-family: 'Playfair Display', sans-serif;
          font-weight: normal;
          letter-spacing: 1px;
          font-size: 23px;
        }

        &-item {
          display: flex;
          flex-direction: row;
          gap: 10px;
          align-items: center;
        }

        &-paragraph {
          color: #FFFFFF;
          font-size: 14px;
          font-weight: 300;
          letter-spacing: 1.5px;
        }

        &-button {
          background-color: rgb(80, 156, 171);
          outline: none;
          font-family: "Roboto", Sans-serif;
          font-size: 12px;
          font-weight: bold;
          line-height: 1em;
          letter-spacing: 4px;
          color: white;
          cursor: pointer;
          height: 40px;
          border: none;
        }
      }
    }
  }

  .error-message {
    background-color: #444;
    color: white;
    display: flex;
    align-items: center;
    padding-left: 10px;
    font-weight: 400;
    height: 30px;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 1px;
    margin-bottom: -25px;
  }

  .icon {
    color: white;
    width: 20px;
    height: 20px;
  }

}