.header-mobile {
  height: 170px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  position: relative;

  &::before {
    content: '';
    background-image: url('../../assets/img/main-background.jpeg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    left: 0;
    right: 0;
    height: 170px;
    filter: opacity(50%)
  }

  &-content {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }

  &-button {
    font-family: "Roboto", Sans-serif;
    font-size: 12px;
    font-weight: bold;
    line-height: 1em;
    letter-spacing: 4px;
    background-color: #373737;
    width: 134px;
    height: 30px;
    color: white;
    border: none;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.logo {
  width: 200px;
  height: 27px;
}

.icon-burger {
  height: 25px;
  width: 25px;
}

.menu {
  position: absolute;
  top: 0;
  left: -80%; /* Початково розташовуємо меню за межами екрану */
  width: 80%; 
  height: 100vh;
  background-color: #373737;
  z-index: 999;
  color: white;
  transition: left 0.3s ease; /* Додаємо перехід для зміни положення */
  padding: 10px;

  &-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-list {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &-link {
    text-decoration: none;
    font-size: 24px;
    color: #fff;
  }
}

.menu.open {
  left: 0; /* Змінюємо положення на 0 при відкритті, висуваючи меню */
}

.block {
  display: block;
}

.none {
  display: none;
}

.icon-close {
  width: 30px;
  height: 30px;
}

@media only screen and (min-width: 1024px) {
  .header-mobile {
    display: none;
  }
}