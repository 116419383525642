.slider-container {
  position: relative;
  width: 100%;
  height: 500px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('../../../../assets/slider/IMG_5605.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.slider-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  /* Затемнення 50% */
  z-index: 0;
}

.slider {
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.slider-track {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.slider-group {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 50px;
  flex: 0 0 100%;
}

.slider-block {
  width: 50px;
  height: 50px;
  margin: 0 10px;
  background-color: lightblue;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  color: white;
  border: none;
  font-size: 24px;
  cursor: pointer;
  z-index: 1;
}

.arrow.left {
  left: 10px;
}

.arrow.right {
  right: 10px;
}

@media only screen and (max-width: 1024px) {
  .slider-container {
    margin-top: 20px;
  }
}