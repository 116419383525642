.card {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.2);
  }

  &-content {
    width: 369px;
    height: 614px;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 60px;

    &--green {
      background-color: #509CAB;
    }

    &--white {
      color: white;
    }
  }

  &-img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    &-container {
      width: 369px;
      height: 274px;
      cursor: pointer;
      transition: transform 0.6s ease;

      &:hover {
        transform: translateY(-20px);
      }
    }
  }

  &-bottom {
    height: 230px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    padding: 0 70px;
    position: relative;

    &__container {
      display: flex;
      align-items: flex-end;
      flex-direction: row;
      gap: 5px;

      p {
        font-family: "Playfair Display", Sans-serif;
      }
    }
  }

  &-title {
    font-size: 12px;
    font-weight: 700;
    height: 12px;
    letter-spacing: 4px;
    line-height: 12px;
  }

  &-price {
    color: #1C1C1C;
    font-family: "Playfair Display", Sans-serif;
    font-size: 50px;
    line-height: 1.3em;

    &--white {
      color: white;
    }
  }

  &-option {
    font-family: "Roboto", Sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 2em;
    letter-spacing: 2px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
  }

  &-button {
    cursor: pointer;
    font-family: "Roboto", Sans-serif;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    line-height: 1em;
    letter-spacing: 4px;
    color: #FFFFFF;
    width: 171px;
    height: 39px;
    border: none;
    transition: background-color 0.2s ease-in-out;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;

    &--green {
      background-color: #509CAB;

      &:hover {
        background-color: #2a2a2a;
      }
    }

    &--white {
      background-color: #ffffff;
      color: #509CAB;

      &:hover {
        background-color: #b98963;
        color: white;
      }
    }
  }
}


@media only screen and (max-width: 1200px) {

  .card {
    &-content {
      width: 280px;
      height: 550px;
    }

    &-img {
      width: 280px;
      height: 210px;

      &-container {
        width: 280px;
        height: 200px;
      }
    }

    &-bottom {
      height: 230px;
      padding: 0 40px;
    }

    &-title {
      font-size: 10px;
    }

    &-price {
      font-size: 45px;
    }

    &-option {
      font-size: 12px;
    }

    &-button {
      font-size: 10px;
    }
  }
}

@media only screen and (max-width: 1023px) {
  .card {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;

    &:hover {
      box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.2);
    }

    &-content {
      width: 290px;
      height: 512px;
      background-color: white;
      display: flex;
      flex-direction: column;
      gap: 40px;

      &--green {
        background-color: #509CAB;
      }

      &--white {
        color: white;
      }
    }

    &-img {
      width: 100%;
      height: 100%;

      &-container {
        width: 290px;
        height: 215px;
        cursor: pointer;
        transition: transform 0.6s ease;

        &:hover {
          transform: translateY(-20px);
        }
      }
    }

    &-bottom {
      padding: 0 20px;
      height: 45%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: start;
      position: relative;
    }

    &-title {
      font-size: 12px;
      font-weight: 700;
      height: 12px;
      letter-spacing: 4px;
      line-height: 12px;
    }

    &-price {
      color: #1C1C1C;
      font-family: "Playfair Display", Sans-serif;
      font-size: 50px;
      line-height: 1.3em;

      &--white {
        color: white;
      }
    }

    &-option {
      font-family: "Roboto", Sans-serif;
      font-size: 14px;
      font-weight: 300;
      line-height: 2em;
      letter-spacing: 2px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 15px;
    }

    &-button {
      cursor: pointer;
      font-family: "Roboto", Sans-serif;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: bold;
      line-height: 1em;
      letter-spacing: 4px;
      color: #FFFFFF;
      width: 171px;
      height: 39px;
      border: none;
      transition: background-color 0.2s ease-in-out;

      &--green {
        background-color: #509CAB;

        &:hover {
          background-color: #2a2a2a;
        }
      }

      &--white {
        background-color: #ffffff;
        color: #509CAB;

        &:hover {
          background-color: #b98963;
          color: white;
        }
      }
    }
  }
}