.menu {
  &-section {
    &-1 {
      height: 1000px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;


      &::before {
        content: '';
        background-image: url('../../assets/img/about-section-1.jpeg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        left: 0;
        right: 0;
        height: 1000px;
        filter: brightness(75%);
        opacity: 90%;
      }

      &-content {
        position: relative;
        background-color: white;
        width: 80%;
        height: 400px;

        &::before {
          content: '';
          background-image: url('../../assets/img/second-background.jpeg');
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
          position: absolute;
          height: 400px;
          width: 80%;
          opacity: 100%;
          filter: opacity(50%)
        }
      }

      &-block {
        position: relative;
        padding: 50px;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }

    &-2 {
      height: 154px;
      width: 80%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: auto;

      &-button {
        width: 195px;
        height: 39px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: bold;
        line-height: 1em;
        letter-spacing: 4px;
        border: none;
        cursor: pointer;
        background-color: #1a4c56;
        color: white;
        transition: transform 0.2s ease-in-out;

        &:hover {
          transform: scale(0.9);
        }
      }
    }
  }
}


@media only screen and (max-width: 750px) {
  .menu-desktop {
    display: none;
  }
}