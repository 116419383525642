.images-block {
  height: 600px;
  width: 100%;
  background-image: url('../../../../assets/img/second-background.jpeg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding: 10px;
  gap: 10px;

  &__container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 10px;
  }

  &__text {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 90%;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    text-align: center;
  }

  &__image {
    border-radius: 10px;
    width: 120%;
    height: 120%;
    transition: transform 0.2s ease-out;
    position: absolute;
    object-fit: cover;
  }
}

@media only screen and (max-width: 670px) {
  .images-block {

    &__text {
      top: 85%;
    }

    height: 900px;
    flex-direction: column;
  }
}