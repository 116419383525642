.gallery {
  &-section {
    &-1 {
      height: 350px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &::before {
        content: '';
        background-image: url('../../assets/img/about-section-4.jpeg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        left: 0;
        right: 0;
        height: 350px;
        filter: brightness(75%);
      }

      &-content {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
      }

      &-title {
        font-family: 'Playfair Display', sans-serif;
        color: #FFFFFF;
        font-size: 60px;
        text-transform: uppercase;
        letter-spacing: 4px;
      }
    }

    &-2 {
      height: 400px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 10px;
      align-items: center;
      width: 100%;
      margin: 50px auto;
      padding: 0 15%;


      &-block-1 {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        text-align: right;
      }

      &-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        gap: 20px;
        padding-bottom: 30px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 325px;
        height: 300px;

        &-2 {
          background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('../../assets/img/gallery1.jpeg');
        }

        &-3 {
          background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('../../assets/img/gallery2.jpeg');
        }
      }

      &-heading {
        color: #FFFFFF;
        font-weight: normal;
        font-size: 23px;
        line-height: 1em;
        letter-spacing: 2px;
        font-family: 'Playfair Display', sans-serif;
      }

      &-desc {
        font-family: 'Roboto', sans-serif;
        color: #FFFFFF;
        font-size: 12px;
        line-height: 1em;
        letter-spacing: 4px;
      }

      &-title {
        width: 400px;
        line-height: 1.5em;
        font-size: 40px;
        letter-spacing: 1px;
        color: #1c1c1c;
        font-family: 'Playfair Display', sans-serif;
        
      }
    }

    &-3 {
      height: 100%;
      margin-bottom: 30px;

      &-grid-container {
        padding: 0 15%;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, minmax(20%, 1fr));
        justify-items: center;
        gap: 5px;
        margin: auto;
      }

      &-grid-item {
        cursor: pointer;

        &:hover {
          filter: brightness(70%);
        }
      }

      &-grid-img {
        width: 100%;
        height: 100%;
      }

      &-bottom {
        padding: 70px 15% 50px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: auto;
        gap: 10px;

        &-block {
          display: flex;
          flex-direction: row;
          gap: 10px;
        }
      }

      &-icon {
        width: 75px;
        height: 75px;
      }

      &-info {
        text-align: center;
        display: flex;
        flex-direction: column;
      }

      &-heading {
        padding-top: 5px;
        margin-bottom: 5px;
        line-height: 1.2em;
        letter-spacing: 1px;
        color: #1c1c1c;
        font-family: 'Playfair Display', sans-serif;
        font-size: 20px;
      }

      &-paragraph {
        width: 100%;
        font-size: 14px;
        font-weight: 300;
        line-height: 2em;
        letter-spacing: 1.5px;
        color: #636363;
        font-family: 'Roboto', sans-serif;
      }
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}

.selected-photo {
  max-width: 90%;
  max-height: 90%;
}

@media only screen and (max-width: 1023px) {
  .gallery {
    &-section {
      &-1 {
        height: 350px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;


        &::before {
          content: '';
          background-image: url('../../assets/img/about-section-4.jpeg');
          background-position: bottom;
          background-repeat: no-repeat;
          background-size: cover;
          position: absolute;
          left: 0;
          right: 0;
          height: 350px;
          filter: brightness(75%);
        }

        &-content {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
        }

        &-title {
          font-family: 'Playfair Display', sans-serif;
          color: #FFFFFF;
          font-size: 40px;
          text-transform: uppercase;
          letter-spacing: 4px;
        }
      }

      &-2 {
        margin-top: 50px;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 30px;

        &-block-1 {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          text-align: right;
          width: 94%;
        }

        &-block {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-end;
          gap: 20px;
          padding-bottom: 30px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          width: 100%;
          height: 300px;

          &-2 {
            background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('../../assets/img/gallery1.jpeg');
          }

          &-3 {
            background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('../../assets/img/gallery2.jpeg');
          }
        }

        &-heading {
          color: #FFFFFF;
          font-weight: normal;
          font-size: 23px;
          line-height: 1em;
          letter-spacing: 2px;
          font-family: 'Playfair Display', sans-serif;
        }

        &-desc {
          font-family: 'Roboto', sans-serif;
          color: #FFFFFF;
          font-size: 12px;
          line-height: 1em;
          letter-spacing: 4px;
        }

        &-title {
          width: 100%;
          text-align: center;
          line-height: 1.5em;
          font-size: 30px;
          letter-spacing: 1px;
          color: #1c1c1c;
          font-family: 'Playfair Display', sans-serif;
        }
      }

      &-3 {
        margin-top: 70px;
        height: 100%;
        margin-bottom: 30px;

        &-grid-container {
          width: 100%;
          padding: 0 15px;
          display: grid;
          grid-template-columns: repeat(4, minmax(70px, 1fr));
          gap: 5px;
          justify-items: center;
          align-items: center;
          margin-top: 70px;
          margin-bottom: 30px;
        }

        &-grid-item {
          cursor: pointer;
          height: 100%;

          &:hover {
            filter: brightness(70%);
          }
        }

        &-grid-img {
          max-width: 100%;
          height: 100%;
        }

        &-bottom {
          padding: 0;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          margin: auto;

          &-block {
            display: flex;
            align-items: center;
            flex-direction: column;
          }
        }

        &-icon {
          width: 75px;
          height: 75px;
          margin-bottom: 20px;
        }

        &-info {
          text-align: center;
          display: flex;
          flex-direction: column;
        }

        &-heading {
          padding-top: 5px;
          margin-bottom: 5px;
          line-height: 1.2em;
          letter-spacing: 1px;
          color: #1c1c1c;
          font-family: 'Playfair Display', sans-serif;
          font-size: 20px;
          margin-bottom: 10px;
        }

        &-paragraph {
          width: 240px;
          font-size: 14px;
          font-weight: 300;
          line-height: 2em;
          letter-spacing: 1.5px;
          color: #636363;
          font-family: 'Roboto', sans-serif;
          margin-bottom: 30px;
        }
      }
    }
  }
}


@media only screen and (min-width: 750px) {
  .gallery {
    &-section {
      &-3 {
        &-bottom {
          flex-direction: row;
          width: 100%;

          &-block {
            width: 100%;
          }
        }
      }
    }
  }
}