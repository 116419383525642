* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

.site-bar-open {
  position: fixed;
  left: 0;
  right: 0;
  height: 100%;
  z-index: 9999;
  transition: transform 0.3s ease;
  transform: translateX(0);
  background-color: rgba(0, 0, 0, 0.5);
}

