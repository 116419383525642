.guests {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  justify-content: space-between;

  &__title {
    font-size: 20px;
    text-align: center;
    padding-bottom: 10px;
  }

  &__container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    gap: 30px;
  }

  &__content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }

  &__count {
    border-radius: 50%;
    border: none;
    background-color: rgb(233, 233, 233);
    padding: 20px;
    height: 55px;
    width: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease;
    color: black;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  &__selected {
    background-color: rgba(0, 0, 0, 0.1);
  }

  &__text {
    margin-top: 10px;
    font-size: 14px;
    text-align: center;
    font-weight: 300;
    padding-bottom: 10px;
  }
}


@media only screen and (max-width: 1100px) {
  .guests {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    justify-content: space-between;

    &__title {
      font-size: 18px;
      text-align: center;
      padding-bottom: 10px;
    }

    &__container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      padding: 10px 20px;
      gap: 30px;
    }

    &__content {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: 20px;
    }

    &__count {
      border-radius: 50%;
      border: none;
      background-color: rgb(233, 233, 233);
      padding: 20px;
      height: 45px;
      width: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.2s ease;

      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }

    &__selected {
      background-color: rgba(0, 0, 0, 0.1);
    }

    &__text {
      font-size: 12px;
      text-align: center;
      padding-bottom: 25px;
      font-weight: 300;
    }
  }
}